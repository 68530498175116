import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/DynamicRouting/HeroSection/HeroSection"
import HelpMerchants from "../components/DynamicRouting/HelpMerchants/HelpMerchants"
import IntelligentRoutingEngine from "../components/DynamicRouting/IntelligentRoutingEngineSection/IntelligentRoutingEngine"

import CascadingSection from "../components/DynamicRouting/Cascading/CascadingSection"
import RoutingSection from "../components/DynamicRouting/RoutingDescription/RoutingSection"
import ConclusionSection from "../components/DynamicRouting/Conclusion/ConclusionSection"
import RoundRobinRoutingSection from "../components/DynamicRouting/RoundRobinRouting/RoundRobinRoutingSection"
import SEO from "../components/Global/SEO/seo"

const dynamicRoutingPage = () => {
  return (
    <Layout headerAbsolute headerTransparent whiteToggler textlight logoDark={false}>
      <SEO page="dynamic-routing" />
      <HeroSection />
      <IntelligentRoutingEngine />
      <HelpMerchants />
      <RoutingSection />
      <RoundRobinRoutingSection/>
      <CascadingSection />
      <ConclusionSection/>
    </Layout>
  )
}

export default dynamicRoutingPage
