import React from "react"
import { RoutingSectionWrapper } from "./RoutingDescription.styles"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"

const RoutingSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "dynamic_routing" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges
  return (
    <RoutingSectionWrapper>
      <Container>
        <Row>
          <Col lg={6} className ="">
            <Row className="my-5 px-4 pl-lg-0 mr-lg-3">
              <Col lg={12}>
                <h2 className="h1 mb-5 text-picton-blue text-lg-center">Static Routing</h2>
                <p className="text-white text-left text-md-center">
                  Static routing is a solution that works for small businesses.
                  This is a simple routing technique where transactions can be
                  routed to different providers using following parameters:
                </p>
                <ul className="static-routing-list text-white text-lg-center ml-0">
                  <li className="px-3"><span className="d-none d-lg-inline">&bull;&nbsp;</span> Organisation</li>
                  <li className="px-3"><span className="d-none d-lg-inline">&bull;&nbsp;</span> Currency</li>
                  <li className="px-3"><span className="d-none d-lg-inline">&bull;&nbsp;</span> Country</li>
                  <li className="px-3"><span className="d-none d-lg-inline">&bull;&nbsp;</span> Priority</li>
                </ul>
              </Col>
              <Col lg={{ offset: 2, span: 8 }} className="my-5 px-4">
                <Img fluid={images[0].node.childImageSharp.fluid} />
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="">
            <Row className="my-5 px-4 pr-lg-0 ml-lg-3 vertical-divider">
              <Col lg={12}>
                <h2 className="h1 mb-5 text-picton-blue text-lg-center">Rule-based Routing</h2>
                <p className="text-white text-left text-md-center">
                  With rule-based routing, merchants will be able to setup
                  routing rules based on several transaction parameters to
                  select best provider/ gateway. Following parameters can be
                  used to define one or more rules:
                </p>
                <Row>
                  <Col lg={4}>
                    <h5 className="text-white">Merchant Data:</h5>
                    <ul className="rule-routing-list text-white">
                      <li>Merchant Country</li>
                      <li>Merchant Website</li>
                    </ul>
                  </Col>
                  <Col lg={4} className="px-lg-0">
                    <h5 className="text-white">Transaction Data:</h5>
                    <ul className="rule-routing-list text-white">
                      <li>Currency</li>
                      <li>Transaction Amount</li>
                      <li>Consumer Country</li>
                      <li>Channel</li>
                      <li>3DS version</li>
                    </ul>
                  </Col>
                  <Col lg={4}>
                    <h5 className="text-white">BIN Data:</h5>
                    <ul className="rule-routing-list text-white">
                      <li>Issuer Country</li>
                      <li>Issuer Name</li>
                      <li>Schema Name</li>
                      <li>Card Type</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col lg={{ offset: 3, span: 6 }} className="my-5 px-4">
                <Img fluid={images[3].node.childImageSharp.fluid} />
              </Col>
              <Col lg={12}>
              <p className="text-white my-5 text-left text-md-center">
                  In addition to above rules can also be defined using custom
                  data fields Default rule must be defined to ensure that
                  transactions which do not match all other rules are still
                  processed.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </RoutingSectionWrapper>
  )
}

export default RoutingSection
