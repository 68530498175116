import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { RoundRobinRoutingSectionWrapper } from "./RoundRobinRoutingSection.styles"
import Table from "./Table"

const content = [
  {
    heading: "Volume Based (Absolute)",
    paragraph: "",
    table: [
      ["Volume Split", "Route To", "Cascade to PSP"],
      ["1000", "PSP1", "PSP2, PSP3, PSP4"],
      ["2500", "PSP1", "PSP3"],
      ["1500", "PSP1", "PSP2, PSP1"],
    ],
  },
  {
    heading: "Day Based ",
    paragraph: "Define smart split rules based on days of the week.",
    table: [
      ["Day Based Routing", "Route To", "Cascade to PSP"],
      ["Monday, Wednesday", "PSP1", "PSP2, PSP3, PSP5"],
      ["Tuesday, Thursday", "PSP1", "PSP3"],
      ["Friday, Saturday & Sunday", "PSP1", "PSP2, PSP1"],
    ],
  },
  {
    heading: "Time Based ",
    paragraph: "Define smart split rules based on time.",
    table: [
      ["Time Based Routing", "Route To", "Cascade to PSP"],
      ["00:00:00 - 12:00:00", "PSP1", "PSP2, PSP3, PSP5"],
      ["12:00:00 - 18:00:00", "PSP1", "PSP3"],
      ["18:00:00 - 00:00:00", "PSP1", "PSP2, PSP1"],
    ],
  },
]
const RoundRobinRoutingSection = () => {
  return (
    <RoundRobinRoutingSectionWrapper className="py-5 px-4 px-lg-0 ">
      <Container className="primary-blue-text">
        <h2 className="h1 my-5 nunito">Smart Split Routing</h2>
        <p className="lead ">
          With smart split, merchants will be able to send transactions to more
          than one acquirer. Four types of smart split rules can be configured:{" "}
        </p>
        <h2 className="secondary-blue-2-text mt-5">
          Volume Based (Percentage)
        </h2>
        <p className="pt-3">
          With volume-based approach, merchants will be able to split and route
          transaction volume to more than one gateway/ acquirer. Rule can be
          defined as x percentage in x volume of transactions format.
        </p>
        <p className="pb-3">
          Example: If every 5000 transactions need to be routed to three PSPs
          (25% to PSP1, 50% to PSP2 and 25% to PSP3), define rule as shown
          below:
        </p>
        <Table
          tableMatrix={[
            ["Volume Split (%)", "Route To", "Cascade to PSP"],
            ["25%", "PSP1", "PSP2, PSP3, PSP4"],
            ["50%", "PSP2", "PSP3"],
            ["25%", "PSP3", "PSP2, PSP1"],
          ]}
        />
        {content.map(({ heading, paragraph, table }) => {
          return (
            <>
              <h2 className="secondary-blue-2-text mt-5">{heading}</h2>
              <p className="py-3">{paragraph}</p>
              <Table tableMatrix={table} />
            </>
          )
        })}
        <p className="mt-5">
          Merchant can opt for Rule based routing, Smart Split or both. If
          merchant is subscribed to both routing methods (Rule based and Smart
          Split), then one or more routing rules can be configured in such a way
          that once routing rule is determined details are passed to smart split
          method. Transaction will be routed to a gateway based on smart split
          rules. This feature gives much more flexibility to not only routing
          transactions based on routing parameters but further route them based
          on time or weekdays.
        </p>
        <p className="font-weight-bold">
          * Cascading mechanism is applied for all transactions irrespective of
          routing type configured.
        </p>
      </Container>
    </RoundRobinRoutingSectionWrapper>
  )
}

export default RoundRobinRoutingSection
