import React from "react"
import { HeroWrapper } from "./HeroSection.styles"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import HeroImage from "./HeroImage"
import { navigateTo } from "../../../utils/hooks"
import { LINKS } from "../../../data/pageLinks.data"

const HeroSection = () => {
  return (
    <HeroWrapper Tag="section">
      <Container
        className="py-5 d-flex flex-column justify-content-around h-100"
        fluid
      >
        <Row className="my-5">
          <Col
            className="d-flex flex-column justify-content-center align-items-baseline px-4"
            lg={{ offset: 1, span: 4 }}
          >
            <div className="mb-4 mt-5 mt-lg-0">
              <h1 className="text-uppercase text-white">
                Intelligent
                <br />
                Routing
                <br />
                Engine <span className="text-uppercase ire-text">(IRE)</span>
              </h1>
            </div>
            <p className="mb-4">
              Enterprise businesses need a complete understanding of how their
              customers want to pay in their major markets in order to optimise
              their payments setup.
            </p>
            <Link to={LINKS.contactUs}>
              <ApButton
                variant="greenTwo"
                className="px-5 mt-2 explore-button"
                role="link"
              >
                Learn More
              </ApButton>
            </Link>
          </Col>
          <Col className="d-none d-lg-block">
            <HeroImage />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  )
}

export default HeroSection
