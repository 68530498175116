import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const IntelligentRoutingEngineWrapper = styled.section`
  .bin-lookup-heading h6 {
    font-size: 1.5em;
  }
  li {
    list-style: none;

    &:before {
      content: "✓";
      color: ${colors.secondaryBlue2};
      padding-right: 15px;
    }
  }
`
