import React from "react"
import { HelpMerchantsSectionWrapper } from "./HelpMerchantSection.styles"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { SURCHARGE_SOLUTIONS } from "../../../data/surchargeInspector.data"

import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"

const HelpMerchants = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "surcharge_inspector/surcharge_solutions" }
        }
        sort: { order: ASC, fields: childImageSharp___fixed___originalName }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.nodes
  return (
    <HelpMerchantsSectionWrapper className="py-5 px-4 px-lg-0">
      <Container className="py-5 text-center primary-blue-text">
        <h2 className="h1 mb-5">
          How <span className="secondary-blue-2-text">APEXX IRE</span> can help
          merchants?
        </h2>
        <p className="mb-5 ">
          <span className="font-weight-bold">APEXX Intelligent Routing</span> is
          a powerful engine that allows flexibility to distribute transaction
          requests to different payment gateways based on defined criteria using
          data in customer’s transaction request. Transactions can be routed to
          right payment solution provider to optimise success rates and reduce
          transaction fee. Using real time analysis across multiple variables
          such as card type, bin data, geo location etc., IRE determines the
          gateway provider to direct these transactions to. Example:
          Transactions from a specific region can be directed to specific
          provider to reduce cross border processing fees and provide better
          approval ratio.
        </p>
        <p className="font-weight-bold  px-lg-5 mx-lg-5">
          Three routing types: Static, Rule Based and Round Robin are available
          to merchants to define routing criteria.
        </p>
      </Container>
    </HelpMerchantsSectionWrapper>
  )
}

export default HelpMerchants
