import React from "react"
import { IntelligentRoutingEngineWrapper } from "./IntelligentRoutingEngine.styles"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CheckList from "../../Global/Common/CheckList/CheckList"

const IntelligentRoutingEngine = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "dynamic_routing" }
          name: { eq: "IRE Section" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const image = data.allFile.edges[0];
  return (
    <IntelligentRoutingEngineWrapper className="py-5 px-4 px-lg-0">
      <Container className="py-5">
        <HeadingWithBg className="bin-lookup-heading" text="Intelligent Routing Engine" />
        <Row>
          <Col lg={6}>
            <h2 className="mb-5 mt-5 primary-blue-text nunito">
              What are the challenges ?
            </h2>
            <h5 className="mb-lg-5 poppins primary-blue-text">
              Merchants in digital (eCommerce) world are often faced with below issues:
            </h5>
            <Img fluid={image} className="w-100 d-flex d-lg-none my-5" />
            <ul className="pl-0">
              <CheckList className="primary-blue-text">How can they reduce costs on cross border transactions ?</CheckList>
              <CheckList className="primary-blue-text">How can they maintain high transaction success rate ?</CheckList>
              <CheckList className="primary-blue-text">How can they provide seamless customer experience ?</CheckList>
            </ul>
          </Col>
          <Col className="d-none d-lg-flex">
            <Img fluid={image && image.node.childImageSharp.fluid} className="w-100" />
          </Col>
        </Row>
      </Container>
    </IntelligentRoutingEngineWrapper>
  )
}

export default IntelligentRoutingEngine
