import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { CascadingSectionWrapper } from "./CascadingSection.styles"
import Image from "gatsby-image"

const CascadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "dynamic_routing" }
          name: { eq: "Cascading" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const image = data.allFile.edges[0];
  return (
    <CascadingSectionWrapper className="py-5 px-4 px-lg-0 secondary-blue-3-background">
      <Container>
        <Row>
          <Col lg={7}>
            <h2 className="h1 mt-5 mb-5 secondary-blue-2-text nunito">
              Cascading
            </h2>
            <p className="text-white">
              Cascading mechanism supports a seamless customer experience. With
              this declined transaction can be automatically routed to secondary
              provider. As this process happens behind the scenes, it results in
              customers completing the sale successfully thus higher approval
              ratio and more volumes can be processed.
            </p>
            <p className="text-white">
              Cascading also ensures that any payment processing issues occurred
              due to disruption to one of the providers (PSP/ acquirer etc)
              services are properly handled. When such event occurs, routing
              engine immediately routes transaction to other available
              providers.
            </p>
            <p className="text-white">
              Highly available systems with backup servers ensures transactions
              are still processed in the event of any physical failure.
            </p>
          </Col>
          <Col
            lg={{ offset: 1, span: 3 }}
            className="pt-5 mt-5 pt-lg-0"
          >
            <Image fluid={image && image.node.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </CascadingSectionWrapper>
  )
}

export default CascadingSection
