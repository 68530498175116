import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { ConclusionSectionWrapper } from "./ConclusionSection.styles"
import Image from "gatsby-image"
import CheckList from "../../Global/Common/CheckList/CheckList"

const ConclusionSection = () => {
  return (
    <ConclusionSectionWrapper className="py-5 px-4 px-lg-0 secondary-blue-3-background">
      <Container>
        <Row>
          <Col >
            <h2 className="h1 my-5 secondary-blue-2-text nunito">
              Conclusion
            </h2>
            <h2 className="text-white">APEXX Intelligent Routing Engine, helps merchants to:</h2>
            <ul className="text-white my-5">
              <CheckList className="text-white">Improve user experience</CheckList>
              <CheckList className="text-white">
                Reduce payment processing fee by identifying best PSP to process
                a specific transaction
              </CheckList>
              <CheckList className="text-white">Increase in global authorization rate</CheckList>
            </ul>
          </Col>
        </Row>
      </Container>
    </ConclusionSectionWrapper>
  )
}

export default ConclusionSection
