import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const HeroWrapper = styled.section`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.201868) 0%, rgba(255, 255, 255, 0.5) 100%), #144056;
  background-blend-mode: overlay, normal;
  box-shadow: 0px 28px 63px -38px rgba(0, 0, 0, 0.5);
  @media (min-width: 992px) {
    height: 100vh;
  }
  width: 100%;
  .ire-text {
    color: ${colors.secondaryBlue}
  }
  h1 {
    @media (min-width: 992px) {
      font-size: 3.2rem;
    }
  }
  svg {
    min-width: 60px;
  }

  p,
  span {
    color: #edf0f2;
    span {
      font-weight: bold;
    }
  }
  .product-image {
    width: 230px;
    &:last-child {
      width: 200px;
    }
  }
  .explore-button {
    @media (max-width:992px) {
      width: 100%;
    }
  }
`
