import styled from "styled-components"

export const HelpMerchantsSectionWrapper = styled.section`

  background: rgba(228, 228, 228, 0.29);
  .surcharge-solution-heading h6 {
    @media (min-width: 992px) {
      font-size: 1.5em;
    }
    font-size: 1.3rem;
  }
  .ss-img {
    max-width: 50px;
  }
  p {
    font-weight: 200;
  }
`
