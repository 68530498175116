import styled from "styled-components"
import { fonts, colors } from "../../../utils/styles"

export const RoutingSectionWrapper = styled.section`
  background: ${colors.secondaryBlue3};
  .vertical-divider {
    &::before {
      content: "";
      width: 1px;
      height: 92%;
      /* margin-top: 3%; */
      background: white;
      position: absolute;
      transform: translateX(-3rem);
    }
    /* border-right: 1px solid white; */
  }
  .static-routing-list {
    li {
      @media (min-width: 992px) {
        display: inline;
      }
      padding-inline-start: 20px;
    }
    list-style-type: disc;
    @media (min-width: 992px) {
      padding-inline-start: 20px;
      display: block;
    }
  }
  .rule-routing-list {
    padding-inline-start: 20px;
    li {
      font-size: 0.9rem;
    }
  }
`
