import React from "react"

function Table({ tableMatrix }) {
  return (
    <table className="table table-bordered">
      {tableMatrix.map((eachRow, index) => {
        const renderedRow = (
          <tr>
            {eachRow.map((eachCol) => {
              return <td>{eachCol}</td>
            })}
          </tr>
        )
        if (index === 0) {
          return <thead className="text-picton-blue">{renderedRow}</thead>
        }
        return renderedRow
      })}
    </table>
  )
}

export default Table
