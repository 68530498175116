import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const RoundRobinRoutingSectionWrapper = styled.section`
  background: #f2f2f2;
  @media (min-width: 992px) {
    table {
      width: 75%;
    }
  }
  thead {
    td {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  th,
  td {
    border: 2px solid ${colors.secondaryBlue2} !important;
  }
`
